import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCarCrash, faCodeCompare, faInfo, faInfoCircle, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBar = ({ onClick, index }: { onClick: Function, index: number }) => (
    <div className="SideBar">
        <div className="Header">
            Traffic
        </div>

        <div className="NavItems">
            <FontAwesomeIcon className="NavItem" onClick={() => onClick(0)} color={index === 0 ? 'black' : 'grey'} size='1x' icon={faCarCrash  as IconProp} />
            <FontAwesomeIcon className="NavItem" onClick={() => onClick(1)} color={index === 1 ? 'black' : 'grey'} size='1x' icon={faCodeCompare as IconProp} />
            <FontAwesomeIcon className="NavItem" onClick={() => onClick(2)} color={index === 2 ? 'black' : 'grey'} fontSize={16} icon={faMap as IconProp} />
            <FontAwesomeIcon className="NavItem" onClick={() => onClick(3)} color={index === 3 ? 'black' : 'grey'} fontSize={16} icon={faInfoCircle as IconProp} />
        </div>

        <div className="Footer">
            Dries & Sean
        </div>
    </div>
)

export default SideBar;