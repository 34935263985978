import { ResponsiveBar, BarDatum } from '@nivo/bar'
import Colors from '../colors.json'
const colors_codes: Record<string, string> = Colors;

const Bar = ({ data }: { data: any }) => {
    console.log(data[0].id);
    return (
        <div className="Bar">
            {data != undefined && <ResponsiveBar
                data={data}
                keys={["value"]}
                layout="horizontal"
                indexBy="id"
                margin={{ top: 0, right: 50, bottom: 40, left: 140 }}
                padding={0.4}
                valueScale={{ type: "linear" }}
                colors={({ indexValue }) => String(colors_codes[indexValue])}
                animate={true}
                maxValue={'auto'}
                enableLabel={true}
                enableGridX={true}
                tooltip={({ id, value, color, indexValue }) => (
                    <div style={{ padding: 12, color, background: '#222222', }}>
                        <strong>{indexValue}: {value}</strong></div>
                )
                }
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: -50,
                    format: function (value: string) {
                        return value.length > 17 ? value.substring(0, 17) + "..." : value;
                    }
                }}

                axisBottom={{
                    tickRotation: 90
                }} />}

        </div>
    );
}

export default Bar;