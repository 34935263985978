import { ResponsiveBar } from '@nivo/bar'
import Colors from '../colors.json'
const colors_codes: Record<string, string> = Colors;
const MultiBar = ({ data, keys, indexBy, groupMode, layout }: { data: any, keys: Array<string>, indexBy: string, groupMode: "stacked" | "grouped", layout: "vertical" | "horizontal" }) => {
    return (
        <div className="Bar">
            {data != undefined &&
                <ResponsiveBar
                    minValue={0}
                    data={data}
                    keys={keys}
                    indexBy={indexBy}
                    groupMode={groupMode}
                    layout={layout}
                    margin={{ top: 10, right: 20, bottom: 30, left: 50 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={({ id }) => String(colors_codes[id])}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.6
                            ]
                        ]
                    }}
                    role="application"
                />
            }
        </div>
    );
}

export default MultiBar;