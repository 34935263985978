import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCross, faCrosshairs, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { Filters } from '../Classes/Vacations/Filters';
import { DataSection } from '../Components/DataSection/DataSection';
import { DateBar } from '../Components/DateBar/DateBar';
import { InfoBar } from '../Components/InfoBar/InfoBar';
import MyResponsiveCalendar from '../Components/Nivo/TimeRange/TimeRange';
import { vacations } from './data/vacations';

export const AccidentContentArea = () => {
    const [activeDateIndex, setActiveDateIndex] = useState(12)
    let dates: Array<string> = getDates();

    const [activeDay, setActiveDay] = useState('')

    const [data, setData] = useState<Array<{ data: Array<object>, id: string }>>([])
    const [dayData, setDayData] = useState<Array<{ data: Array<object>, id: string }>>([])
    const [monthActive, setMonth] = useState(true)
    const [selectActive, setSelectActive] = useState(false)
    const [vacationActive, setVacactionActive] = useState(false)
    const [noVacationActive, setNoVacationActive] = useState(false)

    const [filtersOnClick, setFilters] = useState<Array<string>>([])

    const [isLoading, setIsLoading] = useState(false)

    let timer: NodeJS.Timeout | null = null
    let filters: Filters = new Filters()

    const [selection, setSelection] = useState<Array<{ day: string, value: number }>>([])

    React.useEffect(() => {
        timer = setTimeout(() => {
            setMonth(true)

            const year: string = dates[activeDateIndex]

            request(JSON.stringify({
                "data": [],
                "year": year,
                "filter": "False",
                "filters": filtersOnClick,
                "date": {
                    "first": "01/01/" + year,
                    "second": "31/12/" + + year
                },

                "type": "range",
            }), true)

        }, 1000)

    }, [activeDateIndex])

    return (
        <div className="ContentSection">
            <div className="TopBar">
                <div className="TopBarText">Overview</div>
                <div className="RefreshSection">
                    <button className={(monthActive && filtersOnClick.length === 0) ? "DisabledRefreshButton" : "RefreshButton"} onClick={() => onReset()} disabled={monthActive && filtersOnClick.length === 0}> Refresh </button>
                </div>
            </div>
            <DateBar dates={dates} activeDateIndex={activeDateIndex} activeDay={activeDay} iDateIndex={increaseActiveDateIndex} dDateIndex={decreaseActiveDateIndex} />
            <div className="Filters">
                {filtersOnClick.map(element => (
                    <div className="FilterElement">
                        <div className="FilterText">{element.split("_")[0].localeCompare("day") === 0 ? "Dag: " : element.split("_")[0].localeCompare("month") === 0 ? "Maand: " : "Uur: "}{element.split("_")[1]}</div>
                        <div className="DeleteFilter" onClick={() => deleteFilter(element)}>
                            <FontAwesomeIcon icon={faXmarkCircle as IconProp} />
                        </div>
                    </div>
                )
                )}
            </div>
            <div className="ScrollArea">
                <div className="Select">
                    {selectActive && <button className={vacationActive ? "FilterButtonFill" : "FilterButtonBorder"} onClick={() => applyVacationFilter(false)}>Vacations</button>}
                    {selectActive && <button className={noVacationActive ? "FilterButtonFill" : "FilterButtonBorder"} onClick={() => applyVacationFilter(true)}>No Vacations</button>}

                    {selectActive && <button className={(!selectActive || selection.length === 0) ? "DisabledButton" : "ConfirmButton"} onClick={() => confirmSelection()} disabled={selection.length === 0} > Confirm </button>}
                    {selectActive && <button className={selection.length === 0 ? "DisabledButton" : "ClearButton"} onClick={() => { setSelection([]); setVacactionActive(false); setNoVacationActive(false) }} > Clear </button>}
                    <button className={!selectActive ? "DisabledSelectButton" : "SelectButton"} onClick={() => { setSelectActive(!selectActive) }} > Select </button>
                </div>
                <MyResponsiveCalendar selectActive={selectActive} year={dates[activeDateIndex]} data={selectActive ? selection : (filterData(dayData.length === 0 ? data : dayData, "accident_count_range"))} onPress={onPress} />
                <InfoBar isLoading={isLoading} title={"Aantal accidenten"} data={filterData(dayData.length === 0 ? data : dayData, "accident_victim_type")} />
                <InfoBar isLoading={isLoading} title={"Aantal slachtoffers"} data={filterData(dayData.length === 0 ? data : dayData, "victim_count")} />
                <DataSection setFilter={setFilter} data={dayData.length === 0 ? data : dayData} type={monthActive} />
            </div>
        </div >)

    function setFilter(filterName: string) {
        if (filtersOnClick.includes(filterName))
            return;

        setFilters([...filtersOnClick, filterName])
        const year: string = dates[activeDateIndex]

        let days: string[] = []

        selection.forEach(element => {
            if (days.indexOf(element.day) == -1) days.push(element.day)
        });

        request(JSON.stringify({
            "data": [
                "accident_count_range",
                "accident_victim_type",
                "gender_type",
                "accident_collision_type",
                "victim_count",
                "accident_place",
                "accidents_per_month",
                "accident_count_age_group",
                "accidents_month_type",
                "deadly_vict_gender_age",
                "accidents_per_hour",
                "accidents_hour_type",
                "accidents_day_type",
                "accidents_per_day",
                "accidents_vehicle_type",
            ],
            "year": year,
            "filter": "True",
            "filters": [...filtersOnClick, filterName],
            "date": {
                "first": "01/01/" + year,
                "second": "31/12/" + + year
            },
            "days": days,
            "type": days.length === 0 ? "filter" : "selection",
        }), selection.length === 0 ? true : false)
    }

    function deleteFilter(filterName: string) {
        let tempArray: Array<string> = filtersOnClick.filter(element => element !== filterName)

        setFilters(tempArray);

        const year: string = dates[activeDateIndex]

        let days: string[] = []

        selection.forEach(element => {
            if (days.indexOf(element.day) == -1) days.push(element.day)
        });

        request(JSON.stringify({
            "data": [
                "accident_count_range",
                "accident_victim_type",
                "gender_type",
                "accident_collision_type",
                "victim_count",
                "accident_place",
                "accidents_per_month",
                "accident_count_age_group",
                "accidents_month_type",
                "deadly_vict_gender_age",
                "accidents_per_hour",
                "accidents_hour_type",
                "accidents_day_type",
                "accidents_per_day",
                "accidents_vehicle_type",
            ],
            "year": year,
            "filter": tempArray.length === 0 ? "False" : "True",
            "filters": tempArray,
            "date": {
                "first": "01/01/" + year,
                "second": "31/12/" + + year
            },

            "days": days,
            "type": days.length !== 0 ? "selection" : tempArray.length === 0 ? "range" : "filter",
        }), days.length === 0 ? true : false)
    }

    function applyVacationFilter(noVacations: boolean) {
        if (noVacations) setNoVacationActive(!noVacationActive)
        if (!noVacations) setVacactionActive(!vacationActive)

        let vac: Array<{ start: string, end: string }> = filters.getVacationsFromYear(dates[activeDateIndex])
        let tempArray: Array<string> = filters.createVacationArray(vac)
        let arr: Array<{ day: string, value: number }> = filters.createVacationFilterArray(tempArray, parseInt(dates[activeDateIndex]), noVacations)

        if ((noVacations && !noVacationActive) || (!noVacations && !vacationActive)) setSelection([...arr.concat(selection)])

        else {
            let difference = selection.filter(x => !findDay(x.day, arr));
            setSelection([...difference])
        }
    }

    function findDay(day: string, array: Array<{ day: string, value: number }>) {
        let found = false

        for (let i = 0; i < array.length; i++) {
            if (array[i].day.localeCompare(day) === 0) {
                found = true
                break
            }
        }

        return found
    }

    function onPress(day: string) {
        if (selectActive) { setRange(day); return; }

        setActiveDay(day)

        let tempData = filterData(data, "accident_count_range")

        if (tempData !== undefined)
            setDayData([{ "data": tempData, "id": "accident_count_range" }])

        const year: string = dates[activeDateIndex]

        request(JSON.stringify({
            "data": [
                "accident_victim_type",
                "gender_type",
                "accident_collision_type",
                "victim_count",
                "accident_place",
                "accident_count_age_group",
                "accidents_per_hour",
                "accidents_hour_type",
                "deadly_vict_gender_age",
                "accidents_day_type",
                "accidents_per_day",
                "accidents_vehicle_type"
            ],
            "year": year,
            "filter": filtersOnClick.length === 0 ? "False" : "True",
            "filters": filtersOnClick,
            "day": day,

            "type": "day",
        }), false
        )
    }

    function setRange(day: string) {
        let found = false

        for (let i = 0; i < selection.length; i++) {
            if (selection[i].day.localeCompare(day) === 0) {
                found = true
                break
            }
        }

        if (found) {
            let tempArray = []

            for (let i = 0; i < selection.length; i++)
                if (selection[i].day.localeCompare(day) !== 0) tempArray.push(selection[i])

            setSelection([...tempArray])
        }

        else setSelection([...selection, { 'day': day, 'value': 0 }])
    }

    function confirmSelection() {
        let days: string[] = []
        setSelectActive(false)

        selection.forEach(element => {
            if (days.indexOf(element.day) == -1) days.push(element.day)
        });

        const year: string = dates[activeDateIndex]

        request(JSON.stringify({
            "data": [
                "accident_count_range",
                "accident_victim_type",
                "gender_type",
                "accident_collision_type",
                "victim_count",
                "accident_place",
                "accident_count_age_group",
                "accidents_per_hour",
                "accidents_hour_type",
                "deadly_vict_gender_age",
                "accidents_day_type",
                "accidents_per_day",
                "accidents_vehicle_type"
            ],
            "year": year,
            "filter": filtersOnClick.length === 0 ? "False" : "True",
            "filters": filtersOnClick,
            "days": days,

            "type": "selection",
        }), false
        )

    }

    function increaseActiveDateIndex() {
        if (activeDateIndex === 15) return;

        if (timer != null) clearTimeout(timer);

        update(activeDateIndex + 1)
    }

    function decreaseActiveDateIndex() {
        if (activeDateIndex === 0) return;

        if (timer != null) clearTimeout(timer);

        update(activeDateIndex - 1)
    }

    function update(index: number) {
        setActiveDay('')

        setActiveDateIndex(index)
        setDayData([])
        setData([])
        setVacactionActive(false)
        setNoVacationActive(false)
        setSelectActive(false)
        setFilters([])
    }

    function getDates(): Array<string> {
        var dates: Array<string> = []

        for (var i = 5; i <= 20; i++)
            i < 10 ? dates.push("200" + i) : dates.push("20" + i)

        return dates
    }

    function request(body: any, isYear: boolean) {
        const url: string = "https://ivb.driescardinaels.be/get_data";

        setIsLoading(true)

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            },
            method: 'POST',
            body: body
        })
            .then((response) => response.json())
            .then((json) => {
                if (isYear) setData(json.response)

                else {
                    let tempData: Array<{ data: Array<object>, id: string }> = json.response
                    tempData.push({ "data": filterData(data, "accident_count_range")!, "id": "accident_count_range" })
                    setDayData(tempData)
                    setMonth(false)
                }

                setIsLoading(false)

                console.log(json.response)
            })
            .catch((error) => console.log(error));
    }

    function onReset() {
        update(activeDateIndex)

        const year: string = dates[activeDateIndex]

        request(JSON.stringify({
            "data": [
                "accident_count_range",
                "accident_victim_type",
                "gender_type",
                "accident_collision_type",
                "victim_count",
                "accident_place",
                "accidents_per_month",
                "accident_count_age_group",
                "accidents_month_type",
                "deadly_vict_gender_age",
                "accidents_per_hour",
                "accidents_hour_type",
                "accidents_day_type",
                "accidents_per_day",
                "accidents_vehicle_type",
                "accidents_per_province",
                "accidents_per_city",
                "fatal_accidents_per_location",
            ],
            "year": year,
            "filter": "False",
            "date": {
                "first": "01/01/" + year,
                "second": "31/12/" + + year
            },

            "type": "range",
        }), true)
    }
}

export const filterData = (data: Array<{ data: Array<object>, id: string }>, id: string) => {
    for (let idx = 0; idx < data.length; idx++)
        if (data[idx].id.localeCompare(id) === 0) return data[idx].data
}