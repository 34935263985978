import { ResponsiveLine } from '@nivo/line'

const MyResponsiveLine = ({ data, tooltipX, setFilter }: { data: any, tooltipX: string, setFilter: Function }) => {
    return (
        <div className="MyResponsiveLine">
            {
                data !== undefined && <ResponsiveLine
                    data={data}
                    margin={{ top: 0, right: 55, bottom: 40, left: 75 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: getMinValue(data),
                        max: getMaxValue(data),
                        stacked: true,
                        reverse: false
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 15,
                        tickRotation: 0,
                        legendOffset: -70,
                        legendPosition: 'middle'
                    }}

                    onClick={(point) => setFilter(get(point.data.x))}

                    tooltip={({ point }) => {
                        return (
                            <div
                                style={{
                                    background: '#222222',
                                    padding: '9px 12px',
                                    border: '1px solid #ccc',
                                    textTransform: 'capitalize',
                                    color: point.borderColor
                                }}
                            >
                                <div>{tooltipX.localeCompare('hour') == 0 ? "Uur" : tooltipX.localeCompare('month') == 0 ? 'Maand' : 'Dag'}: {point.data.x} - Ongevallen: {point.data.y}</div>

                            </div>
                        )
                    }}

                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor', modifiers: [] }}
                    enablePointLabel={true}
                    pointLabelYOffset={- 12}
                    useMesh={true}
                />
            }
        </div >

    )

    function get(val: any) {
        let pre: string = tooltipX.localeCompare('hour') == 0 ? "hour" : tooltipX.localeCompare('month') == 0 ? 'month' : 'day'

        if (pre.localeCompare("hour") === 0)
            return pre + "_" + val

        return pre + "_" + map(val)
    }

    function map(key: string) {
        return vals.get(key)
    }

    function getMinValue(arr: Array<{ "id": string, data: [{ "x": string, "y": number }] }>) {
        if (arr.length == 0 || data == undefined) return undefined

        let min: number = 10000

        arr[0].data.forEach((element: { "x": string, "y": number }) => {
            if (min > element.y) min = element.y
        })

        let subValue = min > 10 ? 200 : 5

        return min - subValue < 0 ? 0 : min - subValue
    }

    function getMaxValue(arr: Array<{ "id": string, data: [{ "x": string, "y": number }] }>) {
        if (arr.length == 0 || data == undefined) return undefined

        let max: number = 0

        arr[0].data.forEach((element: { "x": string, "y": number }) => {
            if (max < element.y) max = element.y
        })

        let subValue = max < 30 ? 5 : max < 70 ? 15 : max < 100 ? 25 : 350

        return max + subValue
    }
}
export default MyResponsiveLine;


let vals: Map<string, string> = new Map([
    ["Ma", "maandag"], ["Di", "dinsdag"], ["Wo", "woensdag"], ["Do", "donderdag"], ["Vr", "vrijdag"], ["Za", "zaterdag"], ["Zo", "zondag"],
    ["Jan", "01"], ["Feb", "02"], ["Mrt", "03"], ["Apr", "04"], ["Mei", "05"], ["Jun", "06"], ["Jul", "07"], ["Aug", "08"], ["Sept", "09"], ["Okt", "10"], ["Nov", "11"], ["Dec", "12"],
])
