import { ResponsiveHeatMap } from '@nivo/heatmap'

const MyResponsiveHeatMap = ({ data, isLoading }: { data: any, isLoading: Boolean }) => {
    return (
        <div className="MyResponsiveHeatMap">
            {!isLoading && data !== undefined ?
                <div className="MyResponsiveHeatMapBox">
                    <ResponsiveHeatMap
                        data={data}
                        margin={{ top: 60, right: 40, bottom: 60, left: 90 }}
                        valueFormat=".2d"
                        forceSquare={true}
                        axisTop={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -30,
                            legend: '',
                            legendOffset: 46
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        colors={{
                            type: 'diverging',
                            scheme: 'orange_red',
                            divergeAt: 0.5,
                            minValue: 0,
                            maxValue: 50
                        }}
                        emptyColor="#555555"
                        legends={[
                            {
                                anchor: 'bottom',
                                translateX: 0,
                                translateY: 30,
                                length: 400,
                                thickness: 8,
                                direction: 'row',
                                tickPosition: 'after',
                                tickSize: 3,
                                tickSpacing: 4,
                                tickOverlap: false,
                                tickFormat: '.2s',
                                title: 'Totaal aantal slachtoffers →',
                                titleAlign: 'middle',
                                titleOffset: 4,
                            }
                        ]}
                    />
                </div>
                : <div></div>}
        </div>
    )
}

export default MyResponsiveHeatMap