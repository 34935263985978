import React from 'react'
import { InfoSection } from './InfoSection/InfoSection'

export const InfoBar = ({ title, data, isLoading }: { title: string, data: any, isLoading: boolean }) => {
    return (
        <div className="InfoBar">
            <InfoSection title={title} data={isLoading ? -1 : getTotal()}></InfoSection>
            {!isLoading && data !== undefined && Object.entries(data)
                .map(([key,]) => <InfoSection key={key} title={key} data={getData(key)}></InfoSection>)
            }
        </div >
    )

    function getData(id: string) {
        return data[id]
    }

    function getTotal() {
        if (data === undefined || data === null) return 0

        let sum = 0

        Object.entries(data)
            .map(([, value]) => sum += Number(value))

        return sum
    }
}