import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const DateBar = ({ activeDateIndex, iDateIndex, dDateIndex, dates, activeDay }: { activeDateIndex: number, iDateIndex: Function, dDateIndex: Function, dates: Array<string>, activeDay: string }) => {

    return (
        <div className="DateBar">
            <FontAwesomeIcon color={activeDateIndex === 0 ? "#92939b" : "black"} icon={faCaretLeft as IconProp} className={"IconBig " + (activeDateIndex === 0 ? "NoCursor" : "Pointer")} onClick={() => dDateIndex()} />
            <div className="DateBarText">{dates[activeDateIndex]} {activeDay.localeCompare('') == 0 ?  "" : "(" + activeDay + ")"}</div>
            <FontAwesomeIcon color={activeDateIndex === 15 ? "#92939b" : "black"} icon={faCaretRight as IconProp} className={"IconBig " + (activeDateIndex === 15 ? "NoCursor" : "Pointer")} onClick={() => iDateIndex()} />
        </div>
    )
}