import { useState } from 'react';
import './App.css';
import SideBar from './Components/SideBar/SideBar';
import { CompareContentArea as CompareContentArea1 } from './Layout/CompareContentArea';
import { CompareContentArea as CompareContentArea2 } from './Layout/CompareContentArea';
import { AccidentContentArea } from './Layout/ContentArea';
import MapArea from './Layout/MapArea';

const App = () => {
  const [navIndex, setNavigationIndex] = useState(0)
  const [top, setTop] = useState(0)
  const [filtersOnClick, setFilters] = useState<Array<string>>([])

  return (
    <div className="App">
      <SideBar onClick={(index: number) => setNavigationIndex(index)} index={navIndex} />
      {navIndex === 0 && <AccidentContentArea />}
      {navIndex === 1 &&
        <div className="Compare">
          <CompareContentArea1 filtersOnClick={filtersOnClick} setFilters={setFilters} key={1} top={top} onScroll={onScroll} id="One" />
          <CompareContentArea2 filtersOnClick={filtersOnClick} setFilters={setFilters} key={2} top={top} onScroll={onScroll} id="Two" />
        </div>}
      {navIndex === 2 && <MapArea />}
      {navIndex === 3 &&
        <div className="Center">
          <div className="About">
            <div className="Addressing">
              Hello there!
              <br /><br />
              You have made it to the 'About' page, woohooo! On this page, a brief explanation of our visualization is given. Our project tries to visualize traffic victims and accidents in Belgium. The data used was first made available in 2005 and lastly in 2020.
              <br /><br />
              We will first introduce ourselves. We are Dries Cardinaels and Sean Snellinx and are both Master "Computer Science" Students at <a href="http://www.uhasselt.be">Universiteit Hasselt</a>. We made a tool to visualize traffic victims and accidents in Belgium while keeping the ease of use in mind. To allow personalization, we allow you to filter data per year, month, day, and even hours in our tool by clicking on the graphs. When filtering per year, you can also choose to only filter specific days or select the 'vacation' or 'no vacation' option to see if those options influence accidents. On top of this, you can also compare different years and look at a map that visualizes accidents and victims per region.
              <br /><br />
              This visualisation is made as part of the course <span>Information Visualisation</span> at <a href="http://www.uhasselt.be">Universiteit Hasselt</a> and uses data from <a href="https://statbel.fgov.be/nl/">Statbel</a>. You can download the data <a href="https://statbel.fgov.be/nl/open-data?category=162">here</a>.
            </div>
            <div className="Year">
              2021-2022
            </div>
          </div>
        </div>
      }
    </div>
  );

  function onScroll(scroll: number) {
    setTop(scroll)
  }
}

export default App;