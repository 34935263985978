import { ChoroplethBoundFeature, ResponsiveChoropleth } from '@nivo/geo'
import React from 'react';
import Provinces from '../../../Data/ProvincesGeo.json'

const MyResponsiveProvinceMap = ({ width, height, scale, onClick, data, isLoading }: { width: number, height: number, scale: number, onClick: Function, data: any, isLoading: Boolean }) => {
  return (
    <div className="CityMap">
      <div className="Text">België</div>
      <div className="Province">
      {!isLoading && data !== undefined && Object.entries(data)
                .map(([key,]) =>
        <ResponsiveChoropleth
          data={data[0]["data"]}
          projectionScale={scale}
          features={Provinces.features}
          onClick={(node, event) => showProvince(event, node)}
          margin={{ top: -20, right: 0, bottom: 0, left: 22 }}
          colors="YlOrRd"
          domain={[0, 10000]}
          unknownColor="#666666"
          label="properties.NAME_2"
          valueFormat="d"
          projectionTranslation={getResolution()}
          projectionRotation={[0, 0, 0]}
          graticuleLineColor="#dddddd"
          borderWidth={0.5}
          borderColor="#152538"
          legends={[
            {
                anchor: 'bottom-left',
                direction: 'column',
                justify: true,
                translateX: 20,
                translateY: -100,
                itemsSpacing: 0,
                itemWidth: 94,
                itemHeight: 18,
                itemDirection: 'left-to-right',
                itemTextColor: '#444444',
                itemOpacity: 0.85,
                symbolSize: 18,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000000',
                            itemOpacity: 1
                        }
                    }]
            }
        ]}
        />
      )}

      </div>
    </div>
  )

  function getResolution(): [number, number] {
    const yCenter: number = 1.11 + (scale - 100) / 100 * 1.03
    const xCenter: number = 0.42 + (scale - 100) / 100 * (-0.08)

    const absXCenter: number = xCenter * 100
    const absYCenter: number = yCenter * 100

    const xScale: number = (absXCenter + (50 * (width - 100) / 100)) / width
    const yScale: number = (absYCenter + (50 * (height - 100) / 100)) / height

    return [xScale, yScale]
  }

  function showProvince(event: React.MouseEvent<any, MouseEvent>, node: ChoroplethBoundFeature): void {
    onClick(event, node.data.id);
  }
}

export default MyResponsiveProvinceMap;