import { vacations } from "../../Layout/data/vacations"

export class Filters {
    getVacationsFromYear(year: string): Array<{ start: string, end: string }> {
        for (let i = 0; i < vacations.length; i++)
            if (vacations[i].year === parseInt(year)) return vacations[i].data

        return []
    }

    createVacationArray(array: Array<{ start: string, end: string }>): Array<string> {
        let tempArray: Array<string> = []

        array.forEach((element) => {
            let start: Date = new Date(element.start)
            let end: Date = new Date(element.end)

            for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
                let day: number = d.getDate()
                let month: number = d.getMonth() + 1
                let year: string = d.getFullYear().toString()

                tempArray.push(year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day))
            }
        })

        return tempArray
    }

    createVacationFilterArray(vacationArray: Array<string>, year: number, noVacationActive: boolean): Array<{ day: string, value: number }> {
        let tempArray: Array<{ day: string, value: number }> = []

        for (var d = new Date(year, 0, 1); d <= new Date(year, 12, 31); d.setDate(d.getDate() + 1)) {
            let month: number = d.getMonth() + 1
            let day: number = d.getDate()
            let year: string = d.getFullYear().toString()

            let date = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day)

            if (!vacationArray.includes(date) && noVacationActive)
                tempArray.push({ "day": date, "value": 0 })

            if (vacationArray.includes(date) && !noVacationActive) {
                tempArray.push({ "day": date, "value": 0 })
            }
        }

        return tempArray
    }

    removeVacationSelection(selection: Array<{ day: string, value: number }>, vacationArray: Array<string>, noVacationActive: boolean, vacationActive: boolean): Array<{ day: string, value: number }> {
        let tempArray: Array<{ day: string, value: number }> = []

        selection.forEach(element => {
            // If the date is not a vacationdate and the filter is active
            if (!vacationArray.includes(element.day) && noVacationActive) tempArray.push(element)

            // If the date is a vacation date and the filter is active
            if (vacationArray.includes(element.day) && vacationActive) tempArray.push(element)
        })

        return tempArray
    }
}