import { ResponsiveChoropleth } from '@nivo/geo'
import Cities from '../../../Data/CitiesGeo.json'
import { useState } from 'react';

interface MAP {
    type: string;
    features: Array<GeoMapFeature>;
}

interface GeoMapFeature {
    type: string;
    properties: {
        NAME_2: string;
    };
    geometry: object;
}

const MyResponsiveCityMap = ({ width, height, scale, onReturnClick, provinceName, data, isLoading }: { width: number, height: number, scale: number, onReturnClick: Function, provinceName: string, data: any, isLoading: Boolean }) => {
    const [scaleVal, setScale] = useState(scale);

    const [scaleX, setScaleX] = useState(getTranslation()[0])
    const [scaleY, setScaleY] = useState(getTranslation()[1])

    const [divClicked, setDivClicked] = useState(false)

    const [currValX, setCurrValX] = useState(0)
    const [currValY, setCurrValY] = useState(0)

    let oldTime = new Date();

    return (
        <div className="CityMap" >
            <div className="TopBar">
                <div className="TopBarText">{provinceName}</div>
                <div className="ReturnSection">
                    <button className="ReturnButton" onClick={() => onReturnClick()}> Map België </button>
                </div>
            </div>
            <div className="ZoomButtons">
                <div className="ZoomButton" onClick={() => zoom(true)}>+</div>
                <div className="ZoomButton" style={{ borderTop: '1px solid rgb(76, 76, 76)' }} onClick={() => zoom(false)}>-</div>
            </div>
            <div className="Province" style={{ width: width, height: height }} onMouseDown={(e) => { setDivClicked(true); setCurrValX(e.clientX); setCurrValY(e.clientY) }} onMouseUp={() => setDivClicked(false)} onMouseMove={onMouseDown}>
                {!isLoading && data !== undefined && Object.entries(data)
                    .map(([key,]) =>
                        <ResponsiveChoropleth
                            data={data[0]["data"]}
                            features={filterPerProvince(Cities, provinceName)}
                            onClick={onCityClick}
                            margin={{ top: -20, right: 0, bottom: 0, left: 22 }}
                            colors="YlOrRd"
                            domain={[0, 300]}
                            unknownColor="#666666"
                            label="properties.NAME_4"
                            valueFormat=".3d"
                            projectionScale={scaleVal}
                            projectionTranslation={[scaleX, scaleY]}
                            projectionRotation={[0, 0, 0]}
                            graticuleLineColor="#dddddd"
                            borderWidth={0.5}
                            borderColor="#152538"
                            legends={[
                                {
                                    anchor: 'bottom-left',
                                    direction: 'column',
                                    justify: true,
                                    translateX: 20,
                                    translateY: -100,
                                    itemsSpacing: 0,
                                    itemWidth: 94,
                                    itemHeight: 18,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#444444',
                                    itemOpacity: 0.85,
                                    symbolSize: 18,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000000',
                                                itemOpacity: 1
                                            }
                                        }]
                                }
                            ]}
                        />
                    )}
            </div>
        </div >
    );

    function onMouseDown(e: any) {
        if (Math.abs((new Date().getTime() - oldTime.getTime()) / 1000) > 0.03)
            if (divClicked) {
                const distX = (e.clientX - currValX) / 15000
                const distY = (e.clientY - currValY) / 15000

                setScaleX(scaleX + distX)
                setScaleY(scaleY + distY)
            }
    }

    function zoom(goUp: boolean) {
        goUp ? setScale(scaleVal + 2500) : setScale(scaleVal - 2500)
        goUp ? setScaleY(scaleY + 3.675) : setScaleY(scaleY - 3.675)
        goUp ? setScaleX(scaleX - 0.21) : setScaleX(scaleX + 0.21)
    }

    function onCityClick() {

    }

    function getTranslation(): [number, number] {
        const yCenter: number = 1.11 + (scale - 100) / 100 * 1.03
        const xCenter: number = 0.42 + (scale - 100) / 100 * (-0.08)

        const absXCenter: number = xCenter * 100
        const absYCenter: number = yCenter * 100

        const xScale: number = (absXCenter + (50 * (width - 100) / 100)) / width
        const yScale: number = (absYCenter + (50 * (height - 100) / 100)) / height

        return [xScale, yScale]
    }

    function filterPerProvince(map: MAP, provinceName: String): GeoMapFeature[] {
        return map.features.filter(({ properties }) => properties.NAME_2 === provinceName)
    }
}

export default MyResponsiveCityMap;
