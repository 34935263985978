import MyResponsiveProvinceMap from "../Components/Nivo/ProvinceMap/ProvinceMap";
import MyResponsiveCityMap from "../Components/Nivo/CityMap/CityMap";
import { useState } from "react";
import { DateBar } from "../Components/DateBar/DateBar";
import React from "react";
import MyResponsivePie from "../Components/Nivo/Pie/Pie";
import MyResponsiveHeatMap from "../Components/Nivo/HeatMap/HeatMap";
import MyResponsiveSunburst from "../Components/Nivo/SunBurst/SunBurst";

const MapArea: React.FC = () => {

    const [activeProvince, setActiveProvince] = useState("");

    const [activeDateIndex, setActiveDateIndex] = useState(12)
    let dates: Array<string> = getDates();
    const [activeDay, setActiveDay] = useState('')

    const [data, setData] = useState<Array<{ data: Array<object>, id: string }>>([])
    const [dayData, setDayData] = useState<Array<{ data: Array<object>, id: string }>>([])

    const [isLoading, setIsLoading] = useState(false)

    const [sunburstData, setSunBurstData] = useState({});
    const [sunburstSet, isSunBurstSet] = useState(false);

    let timer: NodeJS.Timeout | null = null

    React.useEffect(() => {
        timer = setTimeout(() => {
            const year: string = dates[activeDateIndex]

            request(JSON.stringify({
                "data": [
                    "accident_count_range",
                    "accident_victim_type",
                    "gender_type",
                    "accident_collision_type",
                    "victim_count",
                    "accident_place",
                    "accidents_per_month",
                    "accident_count_age_group",
                    "accidents_month_type",
                    "deadly_vict_gender_age",
                    "accidents_per_hour",
                    "accidents_hour_type",
                    "accidents_day_type",
                    "accidents_per_day",
                    "accidents_vehicle_type",
                    "accidents_per_province",
                    "accidents_per_city",
                    "fatal_accidents_per_location",
                ],
                "year": year,
                "filter": "False",
                "date": {
                    "first": "01/01/" + year,
                    "second": "31/12/" + + year
                },
                "type": "range",
            }), true)

        }, 1000)

    }, [activeDateIndex])


    let cityData = filterData(dayData.length === 0 ? data : dayData, "accidents_per_city")
    if (cityData && !sunburstSet) {
        isSunBurstSet(true);
        setSunBurstData(formatCityDataSunBurst(cityData));
    }

    return (
        <div className="ContentSection">
            <DateBar dates={dates} activeDateIndex={activeDateIndex} activeDay={activeDay} iDateIndex={increaseActiveDateIndex} dDateIndex={decreaseActiveDateIndex} />

            <div className="ScrollArea">
                <div className="MapContent">
                    {activeProvince !== ""
                        ? <MyResponsiveCityMap scale={10000} width={900} height={700} provinceName={activeProvince} onReturnClick={clearActive} data={filterData(dayData.length === 0 ? data : dayData, "accidents_per_city")} isLoading={isLoading} />
                        : <MyResponsiveProvinceMap width={900} height={700} scale={10000} onClick={receiveProvinceOnClick} data={filterData(dayData.length === 0 ? data : dayData, "accidents_per_province")} isLoading={isLoading} />
                    }
                </div>
                <div className="DataSection">
                    <div className="DataElement">
                        <div className="TopDataItems">
                            <div className="DataTitle">{"Aantal ongevallen per Provincie"}</div>
                        </div>
                        <MyResponsivePie data={getProvinceData(filterData(dayData.length === 0 ? data : dayData, "accidents_per_province"))} />
                    </div>
                    <div className="DataElement">
                        <div className="TopDataItems">
                            <div className="DataTitle">{"Aantal dodelijke ongevallen per weg type, per Provincie"}</div>
                        </div>
                        <MyResponsiveHeatMap data={filterData(dayData.length === 0 ? data : dayData, "fatal_accidents_per_location")} isLoading={isLoading} />
                    </div>

                    {/* <div className="DataElement">
                        <div className="TopDataItems">
                            <div className="DataTitle">{"Aantal ongevallen per Provincie"}</div>
                        </div>
                        <MyResponsiveSunburst data={sunburstData} isLoading={isLoading} />
                    </div> */}
                    {/* <div className="DataElement">
                        <div className="TopDataItems">
                            <div className="DataTitle">{"Aantal ongevallen per Provincie"}</div>
                        </div>
                        <MyResponsivePie data={getProvinceData(filterData(dayData.length === 0 ? data : dayData, "accidents_per_province"))} />
                    </div> */}
                </div>
            </div>
        </div>
    )

    function formatCityDataSunBurst(data: any): Object {
        let provinces = [{ "name": "Limburg", "children": [] as any },
        { "name": "Antwerpn", "children": [] as any },
        { "name": "West-Vlaanderen", "children": [] as any },
        { "name": "Oost-Vlaanderen", "children": [] as any },
        { "name": "Hainaut", "children": [] as any },
        { "name": "LiÃ¨ge", "children": [] as any },
        { "name": "Luxembourg", "children": [] as any },
        { "name": "Namur", "children": [] as any },
        { "name": "Vlaams Brabant", "children": [] as any },
        { "name": "Bruxelles", "children": [] as any },
        { "name": "Brabant Wallon", "children": [] as any }]
        let dat = data[0]["data"]
        for (let i = 0; i < dat.length; i++) {
            let prov = provinces.find(element => element["name"] === dat[i]["province"]);
            let element = { "name": dat[i]["id"], "value": dat[i]["value"] }
            if (prov !== undefined) {
                prov["children"].push(element)
            }
        }
        let liege = provinces.find(element => element["name"] === "LiÃ¨ge")
        if (liege !== undefined)
            liege["name"] = "Liège";
        let result = { "name": "accidents", "children": provinces }

        // console.log(result)
        return result
    }

    function getProvinceData(data: any) {
        if (data === undefined) return [{}]
        else return data[0]["data"]
    }

    function receiveProvinceOnClick(e: React.MouseEvent<any, MouseEvent>, provinceName: string): void {
        setActiveProvince(provinceName);
    }

    function clearActive(e: React.MouseEvent<HTMLButtonElement>): void {
        setActiveProvince("")
    }

    function increaseActiveDateIndex() {
        if (activeDateIndex === 15) return;

        if (timer != null) clearTimeout(timer);

        update(activeDateIndex + 1)
    }

    function decreaseActiveDateIndex() {
        if (activeDateIndex === 0) return;

        if (timer != null) clearTimeout(timer);

        update(activeDateIndex - 1)
    }

    function update(index: number) {
        setActiveDay('')

        setActiveDateIndex(index)
        setDayData([])
        setData([])
    }

    function getDates(): Array<string> {
        var dates: Array<string> = []

        for (var i = 5; i <= 20; i++)
            i < 10 ? dates.push("200" + i) : dates.push("20" + i)

        return dates
    }

    function request(body: any, isYear: boolean) {
        const url: string = "https://ivb.driescardinaels.be/get_data";

        setIsLoading(true)

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            },
            method: 'POST',
            body: body
        })
            .then((response) => response.json())
            .then((json) => {
                if (isYear) {
                    setData(json.response)
                    console.log(json.response)
                }

                else {
                    let tempData: Array<{ data: Array<object>, id: string }> = json.response
                    tempData.push({ "data": filterData(data, "accident_count_range")!, "id": "accident_count_range" })
                    setDayData(tempData)
                }

                setIsLoading(false)

                console.log(json.response)
            })
            .catch((error) => console.log(error));
    }
}

export const filterData = (data: Array<{ data: Array<object>, id: string }>, id: string) => {
    for (let idx = 0; idx < data.length; idx++)
        if (data[idx].id.localeCompare(id) === 0) return data[idx].data
}

export default MapArea;