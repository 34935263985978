import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChartColumn, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Bar from "../../Nivo/Bar/Bar";
import MultiBar from "../../Nivo/Bar/MultiBar";
import StackedBar from "../../Nivo/Bar/MultiBar";
import MyResponsiveLine from "../../Nivo/Line/ResponsiveLine";


export const BarLineElement = ({ title, dataLine, dataBar, indexBy, setFilter }: { title: string, dataLine: any, dataBar: any, indexBy: string, setFilter: Function }) => {
    const [activeItem, setActiveItem] = useState("line");

    return (
        <div className="DataElement">
            <div className="TopDataItems">
                <div className="DataTitle">{title}</div>
                <div className="ChartTypes">
                    <div className="ChartType" onClick={() => setActiveItem("line")}>
                        <FontAwesomeIcon icon={faChartLine as IconProp} color={activeItem.localeCompare("line") === 0 ? "#000" : "#A6ACC0"} fontSize="18px" />
                    </div>
                    <div className="ChartType" onClick={() => setActiveItem("bar")}>
                        <FontAwesomeIcon icon={faChartColumn as IconProp} color={activeItem.localeCompare("bar") === 0 ? "#000" : "#A6ACC0"} fontSize="18px" />
                    </div>
                </div>
            </div>
            {getDataElement()}
        </div>
    )

    function getDataElement() {
        if (dataBar === undefined || dataLine === undefined) return <MyResponsiveLine setFilter={setFilter} tooltipX={"Month"} data={[]} ></MyResponsiveLine>

        if (activeItem.localeCompare("line") === 0) return <MyResponsiveLine setFilter={setFilter} tooltipX={indexBy} data={dataLine} />
        if (activeItem.localeCompare("bar") === 0) return <MultiBar layout="vertical" groupMode={"stacked"} keys={['Langs opzij', 'Langs achteren (of naast elkaar)', 'Frontale botsing (of bij het kruisen)', 'Niet beschikbaar', 'Tegen een hindernis buiten de rijbaan', 'Kettingbotsing (4 bestuurders of meer)', 'Met een voetganger', 'Eén bestuurder, geen hindernis', 'Tegen een hindernis op de rijbaan']}
            data={dataBar} indexBy={indexBy} />
    }
}