
export const DataElement = ({ title, element }: { title: string, element: JSX.Element }) => {
    return (
        <div className="DataElement">
            <div className="TopDataItems">
                <div className="DataTitle">{title}</div>
            </div>
            {element}
        </div>
    )
}