import { filterData } from "../../Layout/ContentArea";
import Bar from "../Nivo/Bar/Bar";
import MultiBar from "../Nivo/Bar/MultiBar";
import StackedBar from "../Nivo/Bar/MultiBar";
import MyResponsiveLine from "../Nivo/Line/ResponsiveLine";
import { BarLineElement } from "./DataElement/BarLineElement";
import { BarPieElement } from "./DataElement/BarPieElement";
import { DataElement } from "./DataElement/DataElement";

export const DataSection = ({ data, type, setFilter }: { data: any, type: boolean, setFilter: Function }) => {
    return (
        <div className="DataSection">
            <BarPieElement data={filterData(data, "accident_collision_type")} title={"Ongevallen per type botsing"} />
            {!deadlyCountIsZero() && <BarPieElement data={filterData(data, "accidents_vehicle_type")} title={"Dodelijke slachtoffers per type voertuig"} />}
            {getBarLineElement()}
            {type && <BarLineElement setFilter={setFilter} indexBy={"day"} title={"Ongevallen per dag"} dataLine={filterData(data, "accidents_per_day")} dataBar={filterData(data, "accidents_day_type")} />}
            {type && <BarLineElement setFilter={setFilter} indexBy={"hour"} title={"Ongevallen per uur"} dataLine={filterData(data, "accidents_per_hour")} dataBar={filterData(data, "accidents_hour_type")} />}
            <BarPieElement title={"Ongevallen per leeftijdscategorie"} data={filterData(data, "accident_count_age_group")} />
            <BarPieElement data={filterData(data, "gender_type")} title={"Slachtoffers per geslacht"} />

            {!deadlyCountIsZero() && <DataElement title={"Dodelijke slachtoffers per leeftijdscategorie en geslacht"} element={<MultiBar layout="vertical" groupMode={"grouped"} keys={['Mannelijk', 'Vrouwelijk', 'Onbekend']}
                data={filterData(data, "deadly_vict_gender_age")} indexBy={"age"} />} />}

        </div>
    )

    function getBarLineElement() {
        if (type) return <BarLineElement setFilter={setFilter} indexBy={"month"} title={"Ongevallen per maand"} dataLine={filterData(data, "accidents_per_month")} dataBar={filterData(data, "accidents_month_type")} />

        return <BarLineElement setFilter={setFilter} indexBy={"hour"} title={"Ongevallen per uur"} dataLine={filterData(data, "accidents_per_hour")} dataBar={filterData(data, "accidents_hour_type")} />
    }

    function deadlyCountIsZero() {
        if (data.length === 0 || data === undefined) return false


        let nData: any = filterData(data, "victim_count")!

        if (nData === undefined) return false

        return nData['Aantal doden'] === 0
    }
}