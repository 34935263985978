export let vacations = [
  {
    "year": 2005,
    "data": [
      // Kerstvakantie begin
      { "start": "2005-01-01", "end": "2005-01-09" },

      //Krokusvakantie
      { "start": "2005-02-07", "end": "2005-02-13" },

      // Paasvakantie
      { "start": "2005-03-28", "end": "2005-04-10" },

      // Zomervakantie
      { "start": "2005-07-01", "end": "2005-08-31" },

      // Herfsvakantie
      { "start": "2005-10-31", "end": "2005-11-06" },

      // Kerstvakantie einde
      { "start": "2005-12-26", "end": "2005-12-31" },
    ]
  },
  {
    "year": 2006,
    "data": [
      // Kerstvakantie begin
      { "start": "2006-01-01", "end": "2006-01-09" },

      //Krokusvakantie
      { "start": "2006-02-27", "end": "2006-03-05" },

      // Paasvakantie
      { "start": "2006-04-03", "end": "2006-04-16" },

      // Zomervakantie
      { "start": "2006-07-01", "end": "2006-08-31" },

      // Herfsvakantie
      { "start": "2006-10-30", "end": "2006-11-05" },

      // Kerstvakantie einde
      { "start": "2006-12-25", "end": "2006-12-31" },
    ]
  },
  {
    "year": 2007,
    "data": [
      // Kerstvakantie begin
      { "start": "2007-01-01", "end": "2007-01-07" },

      //Krokusvakantie
      { "start": "2007-02-19", "end": "2007-02-25" },

      // Paasvakantie
      { "start": "2007-04-02", "end": "2007-04-15" },

      // Zomervakantie
      { "start": "2007-07-01", "end": "2007-08-31" },

      // Herfsvakantie
      { "start": "2007-10-29", "end": "2007-11-04" },

      // Kerstvakantie einde
      { "start": "2007-12-24", "end": "2007-12-31" },
    ]
  },
  {
    "year": 2008,
    "data": [
      // Kerstvakantie begin
      { "start": "2008-01-01", "end": "2008-01-06" },

      //Krokusvakantie
      { "start": "2008-02-04", "end": "2008-02-10" },

      // Paasvakantie
      { "start": "2008-03-24", "end": "2008-04-12" },

      // Zomervakantie
      { "start": "2008-07-01", "end": "2008-08-31" },

      // Herfsvakantie
      { "start": "2008-10-27", "end": "2008-11-02" },

      // Kerstvakantie einde
      { "start": "2008-12-22", "end": "2008-12-31" },
    ]
  },
  {
    "year": 2009,
    "data": [
      // Kerstvakantie begin
      { "start": "2009-01-01", "end": "2009-01-04" },

      //Krokusvakantie
      { "start": "2009-02-23", "end": "2009-03-01" },

      // Paasvakantie
      { "start": "2009-03-30", "end": "2009-04-12" },

      // Zomervakantie
      { "start": "2009-07-01", "end": "2009-08-31" },

      // Herfsvakantie
      { "start": "2009-11-02", "end": "2009-11-08" },

      // Kerstvakantie einde
      { "start": "2009-12-21", "end": "2009-12-31" },
    ]
  },
  {
    "year": 2010,
    "data": [
      // Kerstvakantie begin
      { "start": "2010-01-01", "end": "2010-01-03" },

      //Krokusvakantie
      { "start": "2010-02-15", "end": "2010-02-21" },

      // Paasvakantie
      { "start": "2010-04-05", "end": "2010-04-18" },

      // Zomervakantie
      { "start": "2010-07-01", "end": "2010-08-31" },

      // Herfsvakantie
      { "start": "2010-11-01", "end": "2010-11-07" },

      // Kerstvakantie einde
      { "start": "2010-12-27", "end": "2010-12-31" },
    ]
  },
  {
    "year": 2011,
    "data": [
      // Kerstvakantie begin
      { "start": "2011-01-01", "end": "2011-01-09" },

      //Krokusvakantie
      { "start": "2011-03-07", "end": "2011-03-13" },

      // Paasvakantie
      { "start": "2011-04-11", "end": "2011-04-25" },

      // Zomervakantie
      { "start": "2011-07-01", "end": "2011-08-31" },

      // Herfsvakantie
      { "start": "2011-10-31", "end": "2011-11-06" },

      // Kerstvakantie einde
      { "start": "2011-12-26", "end": "2011-12-31" },
    ]
  },
  {
    "year": 2012,
    "data": [
      // Kerstvakantie begin
      { "start": "2012-01-01", "end": "2012-01-08" },

      //Krokusvakantie
      { "start": "2012-02-20", "end": "2012-02-26" },

      // Paasvakantie
      { "start": "2012-04-02", "end": "2012-04-15" },

      // Zomervakantie
      { "start": "2012-07-01", "end": "2012-08-31" },

      // Herfsvakantie
      { "start": "2012-10-29", "end": "2012-11-04" },

      // Kerstvakantie einde
      { "start": "2012-12-24", "end": "2012-12-31" },
    ]
  },
  {
    "year": 2013,
    "data": [
      // Kerstvakantie begin
      { "start": "2013-01-01", "end": "2013-01-06" },

      //Krokusvakantie
      { "start": "2013-02-11", "end": "2013-02-17" },

      // Paasvakantie
      { "start": "2013-04-01", "end": "2013-04-14" },

      // Zomervakantie
      { "start": "2013-07-01", "end": "2013-08-31" },

      // Herfsvakantie
      { "start": "2013-10-28", "end": "2013-11-03" },

      // Kerstvakantie einde
      { "start": "2013-12-23", "end": "2013-12-31" },
    ]
  },
  {
    "year": 2014,
    "data": [
      // Kerstvakantie begin
      { "start": "2014-01-01", "end": "2014-01-05" },

      //Krokusvakantie
      { "start": "2014-03-03", "end": "2014-03-09" },

      // Paasvakantie
      { "start": "2014-04-07", "end": "2014-04-21" },

      // Zomervakantie
      { "start": "2014-07-01", "end": "2014-08-31" },

      // Herfsvakantie
      { "start": "2014-10-27", "end": "2014-11-02" },

      // Kerstvakantie einde
      { "start": "2014-12-22", "end": "2014-12-31" },
    ]
  },
  {
    "year": 2015,
    "data": [
      // Kerstvakantie begin
      { "start": "2015-01-01", "end": "2015-01-04" },

      //Krokusvakantie
      { "start": "2015-02-16", "end": "2015-02-22" },

      // Paasvakantie
      { "start": "2015-04-06", "end": "2015-04-19" },

      // Zomervakantie
      { "start": "2015-07-01", "end": "2015-08-31" },

      // Herfsvakantie
      { "start": "2015-11-02", "end": "2015-11-08" },

      // Kerstvakantie einde
      { "start": "2015-12-21", "end": "2015-12-31" },
    ]
  },
  {
    "year": 2016,
    "data": [
      // Kerstvakantie begin
      { "start": "2016-01-01", "end": "2016-01-03" },

      //Krokusvakantie
      { "start": "2016-02-08", "end": "2016-02-14" },

      // Paasvakantie
      { "start": "2016-03-28", "end": "2016-04-10" },

      // Zomervakantie
      { "start": "2016-07-01", "end": "2016-08-31" },

      // Herfsvakantie
      { "start": "2016-10-31", "end": "2016-11-06" },

      // Kerstvakantie einde
      { "start": "2016-12-26", "end": "2016-12-31" },
    ]
  },
  {
    "year": 2017,
    "data": [
      // Kerstvakantie begin
      { "start": "2017-01-01", "end": "2017-01-08" },

      //Krokusvakantie
      { "start": "2017-02-27", "end": "2017-03-05" },

      // Paasvakantie
      { "start": "2017-04-03", "end": "2017-04-17" },

      // Zomervakantie
      { "start": "2017-07-01", "end": "2017-08-31" },

      // Herfsvakantie
      { "start": "2017-10-30", "end": "2017-11-05" },

      // Kerstvakantie einde
      { "start": "2017-12-25", "end": "2017-12-31" },
    ]
  },
  {
    "year": 2018,
    "data": [
      // Kerstvakantie begin
      { "start": "2018-01-01", "end": "2018-01-07" },

      //Krokusvakantie
      { "start": "2018-02-12", "end": "2018-02-18" },

      // Paasvakantie
      { "start": "2018-04-02", "end": "2018-04-15" },

      // Zomervakantie
      { "start": "2018-07-01", "end": "2018-08-31" },

      // Herfsvakantie
      { "start": "2018-10-29", "end": "2018-11-04" },

      // Kerstvakantie einde
      { "start": "2018-12-24", "end": "2018-12-31" },
    ]
  },
  {
    "year": 2019,
    "data": [
      // Kerstvakantie begin
      { "start": "2019-01-01", "end": "2019-01-06" },

      //Krokusvakantie
      { "start": "2019-03-04", "end": "2019-03-10" },

      // Paasvakantie
      { "start": "2019-04-08", "end": "2019-04-22" },

      // Zomervakantie
      { "start": "2019-07-01", "end": "2019-08-31" },

      // Herfsvakantie
      { "start": "2019-10-28", "end": "2019-11-03" },

      // Kerstvakantie einde
      { "start": "2019-12-23", "end": "2019-12-31" },
    ]
  },
  {
    "year": 2020,
    "data": [
      // Kerstvakantie begin
      { "start": "2020-01-01", "end": "2020-01-05" },

      //Krokusvakantie
      { "start": "2020-02-24", "end": "2020-03-01" },

      // Paasvakantie
      { "start": "2020-04-06", "end": "2020-04-19" },

      // Zomervakantie
      { "start": "2020-07-01", "end": "2020-08-31" },

      // Herfsvakantie
      { "start": "2020-11-02", "end": "2020-11-15" },

      // Kerstvakantie einde
      { "start": "2020-12-21", "end": "2020-12-31" }
    ]
  },
]