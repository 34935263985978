import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChartColumn, faPieChart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Bar from "../../Nivo/Bar/Bar";
import MyResponsivePie from "../../Nivo/Pie/Pie";


export const BarPieElement = ({ title, data }: { title: string, data: any }) => {
    const [activeItem, setActiveItem] = useState("pie");

    return (
        <div className="DataElement">
            <div className="TopDataItems">
                <div className="DataTitle">{title}</div>
                <div className="ChartTypes">
                    <div className="ChartType" onClick={() => setActiveItem("pie")}>
                        <FontAwesomeIcon icon={faPieChart as IconProp} color={activeItem.localeCompare("pie") === 0 ? "#000" : "#A6ACC0"} fontSize="18px" />
                    </div>
                    <div className="ChartType" onClick={() => setActiveItem("bar")}>
                        <FontAwesomeIcon icon={faChartColumn as IconProp} color={activeItem.localeCompare("bar") === 0 ? "#000" : "#A6ACC0"} fontSize="18px" />
                    </div>
                </div>
            </div>
            {getDataElement(data)}
        </div>
    )

    function getDataElement(data: any) {
        if (data === undefined) return <MyResponsivePie data={[]} ></MyResponsivePie>

        if (activeItem.localeCompare("pie") === 0) return <MyResponsivePie data={data} />
        if (activeItem.localeCompare("bar") === 0) return <Bar data={data} />
    }
}