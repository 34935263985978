import { ResponsiveCalendar } from "@nivo/calendar";
import { ReactChild, ReactFragment, ReactPortal } from "react";

const MyResponsiveCalendar = ({ year, data, onPress, selectActive }: { year: string, data: any, onPress: Function, selectActive: boolean }) => {
    const CustomTooltip = (data: { value: {} | null | undefined; color: any; day: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
        if (data.value === undefined) return null
        return (
            < span style={{ color: data.color, backgroundColor: '#232222', padding: '10px' }
            }>

                {data.day} {data.value == 0 ? "" : ":"} {data.value == 0 ? "" : data.value}
            </span >
        )

    }

    return (<div className="TimeRange">
        <ResponsiveCalendar
            data={data === undefined ? [] : data}
            from={year + "-01-01"}
            to={year + "-12-31"}
            emptyColor="#eeeeee"
            colors={['#029980', '#97e3d5', '#e8c1a0', '#f47560']}
            yearSpacing={40}
            monthSpacing={15}
            monthLegendOffset={12}
            monthBorderColor="#ffffff"
            tooltip={CustomTooltip}
            dayBorderWidth={3}
            onClick={(data) => onPress(data['day'])}
            dayBorderColor="#ffffff"
            margin={{ top: 0, right: 40, bottom: 40, left: 40 }}
            legends={selectActive ? [] : [
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    justify: false,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left',
                    translateX: -60,
                    translateY: -60,
                    symbolSize: 20
                }
            ]}
        />

    </div>)
}
export default MyResponsiveCalendar;